<template>
  <app-modal-payment-link-detail
    :show="showModalPaymentLinkDetail"
    @close="showModalPaymentLinkDetail = false"
    :payment_link="selectPaymentLink"
    @update:payment_link="selectPaymentLink = $event"
  />
  <app-modal-withdraw
    :show="showModalWithdraw"
    @close="showModalWithdraw = false"
    @refresh="refresh"
  />
  <div>
    <div class="px-4 border-b pt-6 pb-4">
      <div class="flex container items-center justify-between">
        <p class="font-medium">{{ $t("wallet.overview.title") }}</p>
        <app-button-outline
          class="flex flex-row space-x-2"
          @click="onClickWithdraw"
        >
          <app-icon name="LibraryIcon" class="h-4 w-4" />
          <p>{{ $t("wallet.payout.withdraw") }}</p>
        </app-button-outline>
      </div>
    </div>
  </div>
  <div class="grid grid-rows-1 md:grid-cols-3 gap-3 my-10 lg:mx-0 mx-5 text-gray-600">
    <button
      class="
        shadow
        p-7
        bg-purple-100
        rounded
        focus:outline-none focus:ring focus:ring-primary
        text-left
      "
      :class="[
        {
          'ring ring-primary': isPaymentLink,
        },
      ]"
      @click="fetchListPaymentLink()"
    >
      <p class="text-md font-medium">
        {{ $t("wallet.overview.on_the_way_swipe") }}
      </p>
      <paragraph-shimmer
        :is-loading="balanceOverviewLoading"
        :lines="1"
        :random-size="true"
      />
      <p class="text-2xl" v-if="!balanceOverviewLoading">
        {{
          $formats.currency(
            balanceOverview.currency,
            balanceOverview.total_payment_paid
          )
        }}
      </p>
    </button>
    <button
      class="
        shadow
        p-7
        bg-purple-100
        rounded
        focus:outline-none focus:ring focus:ring-primary
        text-left
      "
      :class="[
        {
          'ring ring-primary': showTransactionTable,
        },
      ]"
      @click="showWalletTransaction"
    >
      <p class="text-md font-medium">
        {{ $t("wallet.overview.expected_payout") }}
      </p>
      <paragraph-shimmer
        :is-loading="balanceOverviewLoading"
        :lines="1"
        :random-size="true"
      />
      <p class="text-2xl" v-if="!balanceOverviewLoading">
        {{
          $formats.currency(
            balanceOverview.currency,
            balanceOverview.total_expected_payout
          )
        }}
      </p>
    </button>
    <button
      class="
        shadow
        p-7
        bg-white
        rounded
        focus:outline-none focus:ring focus:ring-primary
        text-left
      "
      :class="[
        {
          'ring ring-primary': showPayoutTable,
        },
      ]"
      @click="showPayoutPage"
    >
      <p class="text-md font-medium">
        {{ $t("wallet.overview.payout") }}
      </p>
      <paragraph-shimmer
        :is-loading="balanceOverviewLoading"
        :lines="1"
        :random-size="true"
      />
      <p class="text-2xl" v-if="!balanceOverviewLoading">
        {{
          $formats.currency(
            balanceOverview.currency,
            balanceOverview.total_payout
          )
        }}
      </p>
    </button>
  </div>
  <div v-if="isPaymentLink">
    <app-table
      :loading="paymentLinkLoading"
      :apiResponse="apiPaginationPaymentLink.data"
      :showNumber="false"
      @params-changed="paramsChanged"
      :showEmptyButton="false"
      :filters="filterPaymentLink"
      @on-item-click="onItemClick"
    >
      <template v-slot:header>
        <th v-if="isMasterBusinessRole" class="font-bold">
          {{ $t("wallet.transaction.business_name") }}
        </th>
        <th class="font-bold">{{ $t("wallet.overview.header.amount") }}</th>
        <th class="font-bold">{{ $t("wallet.overview.header.charges") }}</th>
        <th class="font-bold">{{ $t("wallet.overview.header.net_amount") }}</th>
        <th class="font-bold">&nbsp;</th>
        <th class="font-bold">{{ $t("wallet.overview.header.title") }}</th>
        <th class="font-bold">{{ $t("wallet.overview.header.email") }}</th>
        <th class="font-bold">{{ $t("wallet.overview.header.date") }}</th>
      </template>

      <template v-slot:body="slotData">
        <td v-if="isMasterBusinessRole">
          {{ slotData.model.business.name }}
        </td>
        <td>
          <p class="font-medium">
            {{
              $formats.currency(
                slotData.model.currency,
                slotData.model.amount ?? 0.0
              )
            }}
          </p>
        </td>
        <td>
          <p class="font-medium">
            {{
              $formats.currency(
                slotData.model.currency,
                getPaymentAmount(slotData.model.payments ?? []).charges
              )
            }}
          </p>
        </td>
        <td>
          <p class="font-medium">
            {{
              $formats.currency(
                slotData.model.currency,
                getPaymentAmount(slotData.model.payments ?? []).net_amount
              )
            }}
          </p>
        </td>
        <td class="flex place-items-center space-x-2 justify-between">
          <app-badge :status="'success'" class="flex space-x-1 text-xs">
            <p>
              {{ $t("wallet.overview.succeded") }}
            </p>
          </app-badge>
        </td>
        <td>
          {{ slotData.model.title }}
        </td>
        <td>
          {{ slotData.model.email }}
        </td>
        <td>
          {{ $moment(slotData.model.created_at).format("DD MMM YYYY hh:mm a") }}
        </td>
      </template>
    </app-table>
  </div>

  <div v-if="showPayoutTable">
    <payoutPage />
  </div>
  <div v-if="showTransactionTable">
    <transactionPage />
  </div>
</template>

<script>
import PAYOUT_STATUS from "@/utils/const/payout_status";
import BUSINESS_STATUS from "@/utils/const/business_status";
import payoutPage from "../payout/index-page.vue";
import transactionPage from "../transaction/index-page.vue";
import { ParagraphShimmer } from "vue3-shimmer";

export default {
  components: {
    ParagraphShimmer,
    payoutPage,
    transactionPage,
  },
  data() {
    return {
      queryParams: "",
      originalQueryParams: "",
      showModalWithdraw: false,
      showPayoutTable: false,
      showTransactionTable: false,
      showModalPaymentLinkDetail: false,
      selectPaymentLink: null,
      loadFilterFromUrl: false,
      isPaymentLink: true,
      PAYOUT_STATUS: PAYOUT_STATUS,
      filterPaymentLink: [],
    };
  },
  computed: {
    apiPaginationTransaction() {
      return this.$store.getters["walletStore/apiPaginationResponse"];
    },

    apiPaginationPaymentLink() {
      return this.$store.getters["paymentStore/apiPaginationResponse"];
    },

    payoutLoading() {
      return this.$store.getters["walletStore/apiPaginationLoading"];
    },

    paymentLinkLoading() {
      return this.$store.getters["paymentStore/apiPaginationLoading"];
    },

    balanceOverviewLoading() {
      return this.$store.getters["walletStore/balanceOverviewLoading"];
    },

    balanceOverview() {
      return this.$store.getters["walletStore/balanceOverview"];
    },

    isMasterBusinessRole() {
      return this.$store.getters["authStore/isMasterBusinessRole"];
    },

    business() {
      return this.$store.getters["businessStore/business"];
    },

    all_business() {
      return this.$store.getters["masterBusinessStore/all_business_for_select"];
    },
  },

  beforeMount() {
    if (
      !this.$lodash.isEmpty(this.$route.query) &&
      this.$route.query["filter[has_status]"] != null
    ) {
      this.loadFilterFromUrl = true;
      this.queryParams = (this.queryParams == "") ? "?filter[has_status]" + this.$route.query["filter[has_status]"] :
        this.queryParams + "&filter[has_status]" + this.$route.query["filter[has_status]"]
    }
  },

  async mounted() {
    this.$store.dispatch(
      "walletStore/retrieveBalanceOverview",
      this.isMasterBusinessRole
    );

    await this.$store.dispatch("masterBusinessStore/getMasterBusiness", {
      business_status_id: BUSINESS_STATUS.APPROVED,
    });

    this.filterPaymentLink = [
      {
        key: "filter[title]",
        type: "text",
        placeholder: this.$t("payment.link.filter.title"),
        value: "",
      },
      {
        key: "filter[email]",
        type: "text",
        placeholder: this.$t("payment.link.filter.email"),
        value: "",
      },
      {
        key: "filter[currency]",
        type: "text",
        placeholder: this.$t("payment.link.filter.currency"),
        value: "",
      },
      {
        key: "filter[amount]",
        type: "number",
        placeholder: this.$t("payment.link.filter.amount"),
        value: "",
      },
      {
        key: "filter[payment_link_id]",
        type: "text",
        placeholder: this.$t("payment.link.filter.payment_link_id"),
        value: "",
      },
      {
        key: "filter[attempt_id]",
        type: "text",
        placeholder: this.$t("payment.link.filter.payment_attempt_id"),
        value: "",
      },
      {
        key: "filter[settlement_id]",
        type: "text",
        placeholder: this.$t("payment.link.filter.settlement_id"),
        value: "",
      },
      {
        key: "filter[created_at_between]",
        type: "date",
        placeholder: this.$t("payment.link.filter.date_created_at"),
        value: "",
      },
    ];

    if (this.isMasterBusinessRole) {
      this.filterPaymentLink.unshift({
        key: "filter[business_id]",
        type: "select",
        placeholder: this.$t("payout.table.account.header_business_name"),
        items: this.all_business,
      });
    }
    this.fetchListPaymentLink();
  },

  methods: {
    getStatus(status) {
      switch (status) {
        case PAYOUT_STATUS.APPROVED:
          return "success";
        case PAYOUT_STATUS.PROCESSING:
          return "light";
        case PAYOUT_STATUS.REQUESTED:
          return "light";
        case PAYOUT_STATUS.REJECTED:
          return "error";
      }
    },

    paramsChanged(filters, queryParams) {
      this.originalQueryParams = queryParams;
      this.queryParams = queryParams;
      if (this.filters && this.filters["filter[business_id]"] != null) {
        this.filters["filter[business_id]"] = filters["filter[business_id]"]?.id;
      }
      if (this.isPaymentLink) {
        this.queryParams = (this.queryParams == "") ? "?filter[has_status]=paid" :
          this.queryParams + "&filter[has_status]=paid";

        this.$store.dispatch("paymentStore/retrievePaymentLinks", this.queryParams);
      }
    },

    showPayoutPage() {
      this.showPayoutTable = true;
      this.isPaymentLink = false;
      this.showTransactionTable = false;
    },

    showWalletTransaction() {
      this.showTransactionTable = true;
      this.isPaymentLink = false;
      this.showPayoutTable = false;
    },

    fetchListPaymentLink() {
      this.isPaymentLink = true;
      this.showPayoutTable = false;
      this.showTransactionTable = false;
      this.queryParams = (this.originalQueryParams == "") ? "?filter[has_status]=paid" :
        this.originalQueryParams + "&filter[has_status]=paid";
      this.$store.dispatch("paymentStore/retrievePaymentLinks", this.queryParams);
    },

    refresh() {
      window.location.href = this.$route.path;
    },

    onItemClick(data) {
      if (this.isPaymentLink) {
        this.showModalPaymentLinkDetail = !this.showModalPaymentLinkDetail;
        this.selectPaymentLink = data;
      } 
    },

    onClickWithdraw() {
      this.showModalWithdraw = !this.showModalWithdraw;
    },

    copyUrl(model) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(model.payment_url);

      this.$notify(
        {
          group: "success",
          title: this.$t("payment.link.copy_link"),
        },
        5000
      );
    },

    async resendEmail(model) {
      const resendEmail = await this.$store.dispatch(
        "paymentStore/resendEmail",
        model._id
      );

      if (resendEmail == null) return;

      this.$notify(
        {
          group: "success",
          title: this.$t("payment.link.resend"),
        },
        5000
      );
    },

    getPaymentAmount(payments) {
      var paymentAmount = {
        net_amount: null,
        amount: null,
        charges: null,
      };

      payments.forEach((payment) => {
        paymentAmount.net_amount += payment.settlement_amount;
        paymentAmount.amount += payment.amount;
        paymentAmount.charges +=
          (payment.amount - payment.settlement_amount) * -1;
      });

      return paymentAmount;
    },
  },
};
</script>
